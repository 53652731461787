


@media screen and (max-width: 600px) {
  .nav-button { 
    display: none;
  }
  }
  
    
  @media screen and (min-width: 600px) { 
    .videoContainer {
      max-width: 600px;
      position: absolute;
      left: 50%;
      width: 80vh im !important; 
      transform: translate(-50%, 0%);
    }

  }