@import './font.css';

.app {
  height: 100vh;
  width: 100vw;
  
  -ms-overflow-style: none;
  scrollbar-width: none; 
  background-color: rgba(32,1,35,1);
  /* display: grid;
  place-items: center; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app::-webkit-scrollbar {
  display: none;
}


.swipper-container {
  margin-top: -30% !important;
}

button,
textarea,
input,
select,
div,
a{
 -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
 -webkit-tap-highlight-color: transparent !important;
 -webkit-user-select: none !important;
 -khtml-user-select: none !important;
 -moz-user-select: none !important;
 -ms-user-select: none !important;
  user-select: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  

}
:focus {
  outline: none; /* no outline - for most browsers */
  box-shadow: none; /* no box shadow - for some browsers or if you are using Bootstrap */
}