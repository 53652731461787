html, body {
  margin: 0;
  font-family: 'Gotham Black';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: unset;
}

code {
  font-family: 'Gotham Black';
}

body::-webkit-scrollbar {
  display: none;
}
