.textboxInput {
  max-width: 100%;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
  left: 0;
  right: 0;
}

input[type="text"],
select {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  border: 1px solid white;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: transparent;
  color: white;
  font-weight: 500;
}

input:focus {
  border: 2px;
  border-color: white;
}
