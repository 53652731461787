.root {
    width: 100vw;
    border-top: 1px solid rgbA(151, 151, 151, 0.4);
    position: absolute;
    bottom: 0px;
    height: 50px !important;
    background-color: rgba(17,2,21) !important;
    padding-bottom: env(safe-area-inset-bottom);
    position: fixed;
}

.root > a {
    max-width: 130px;
}