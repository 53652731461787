.elipsisIcon {
  color: white;
  height: 60px !important;
  width: 60px !important;
  position: absolute !important;
  margin: 5px;
  z-index: 99;
}

.leaderProfileContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.featuredVideoContainer {
  width: 100%;
  height: 30vh;
}

.bigPlayButton {
  display: none !important;
}

.featuredVideo {
  position: relative;
  padding-top: 0% !important;
}

.profileBodyContainer {
  position: relative;
  height: 100%;
  width: 100%;
}



.profileImage {
  margin-left: 10px;
  height: 50px;
  width: 50px;
  
}

.artistProfile {
  position: absolute;
  top: -5%;
  left: 5%;
  display: flex;
  flex-direction: column;
}

.artistName {
  color: white;
  font-weight: bold;
  font-size: 27px;
  /* margin-top: 10px; */
  margin-left: 10px;
}

.artistBadge {
  margin-left: 10px;
  border: 1px solid white;
  border-radius: 30px;
}

.badgeText {
  font-size: 14px;
  padding: 2px 15px;
  color: white;
  font-weight: bold;
}

.profileHeaderContainer {
  display: flex;
  position: relative;
  height: 1vh;
  width: 100%;
}

/* Video */
.containerFullScreen {
  height: 100%;
  position: relative;
}

.artistRanking {
  position: absolute;
  top: 9%;
  left: -2px;
  background-color: #ef233c;
  height: 20px;
  width: 65px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 7px 10px;
  font-weight: bold;
  font-size: 30px;
  z-index: 1;
}

.closeReturnToCard {
  position: absolute;
  top: 9%;
  right: 7%;
  color: black;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.imageContainer {
  height: 100%;
  width: 100%;
}

.videoSideBar {
  position: absolute;
  bottom: 2%;
  right: 10px;
  color: white;
  display: flex;
  flex-direction: column;
 
}

.videoSideBarButton {
  padding: 5px;
  text-align: center;
  color: white;
}

.totalVotesCounter {
  padding: 5px;
  text-align: center;
  color: white;
  font-size: 17px;
  font-weight: bolder;
}

.cardBottom {
  position: absolute;
  height: 19%;
  background-color: black;
  opacity: 0.75;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}


.heartIconCard {
  height: 10px;
  width: 10px;
  margin-right: 8px;
}

.shareIconCard {
  height: 15px;
  width: 15px;
  margin-right: 8px;
}

.flexHeart {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #ef233c;
  margin-left: 5%;
}

.flexShare {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.imageContainer > .video-react-video {
  object-fit: fill !important;
}

/* Video */

/* Header */
.shareIconHeaderContainer {
  margin: auto;
}

.shareIconHeader {
  height: 35px;
  width: 35px;
}

.profileTabsContainer {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.tabsBar {
  background-color: transparent !important;
}

.activeTab {
  color: red !important;
  font-weight: bold !important;
}

.inactiveTab {
  color: white !important;
  font-weight: bold !important;
}

.tabPanel {
  height: 45vh;
  width: 100%;
  overflow-y: auto;
}

.profileSectionHeader {
}

.topBorderSection {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.bottomBorderSection {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.profileSectionHeaderContainer {
  padding: 10px 20px;
  overflow-x: auto;
}

.locationCityProfile {
  display: flex;
  color: rgb(29, 32, 41);
  padding: 5px 0px;
}

.aboutText {
  color: white;
  font-weight: bold !important;
  font-size: 14px !important;
}

.aboutInfo {
  color: white;
  padding: 5px 0px;
}

.cityText {
  font-size: 14px !important;
}

.locationIcon {
  width: 20px;
  height: 20px;
}

.tabsRoot {
  font-size: 0.875rem;
  text-align: center;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.02857em;
  text-transform: none !important;
}

.tabsHeader {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

/* HEader */

/* YouTubeVideo */
.youTubeVideo {
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.youTubeVideosContainer {
  padding: 10px 0px;
  display: inline-flex;
  /* flex-wrap: wrap; */
  margin: -15px 0 0 -15px;
  overflow-x: scroll !important;
}

.youTubeVideosContainer > div {
  margin: 15px 0 0 15px;
}

.playIcon {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  color: white;
}

.playIcon > svg {
  height: 40px;
  width: 40px;
}

/* YouTubeVideo */

/* SocialMedia */
.socialMediaContainer {
  padding: 10px 0px;
  display: inline-flex;
  /* flex-wrap: wrap; */
  margin: -15px 0 0 -15px;
}

.socialMediaContainer > div {
  margin: 15px 0 0 15px;
}

.socialMediaLink {
  height: 40px;
  width: 40px;
  background-color: #503952;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: white;
}

.socialMediaLinkIcon {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  /* background-position: center; */
}

/* SocialMedia */
.smallMargin {
  margin-right: 10px;
}

.profileLoading {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-content: center;
}

.dialog {
  /* background-color: white; */
  /* position: "absolute" !important;
  left: 0px !important;
  bottom: 0px !important; */
  background-color: #110215 !important;
  color: black !important;
  /* width: 100% !important; */
  margin: 0;
  max-width: 500px !important;
}


.profileModalDialog {
  padding: 0px !important;
  margin: 0px !important;
  padding-top: 0 !important;
}


.artistCardContainer {
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}

.artistCard {
  position: relative;
  /* left: 50%; */
  top: 0;
  /* padding-bottom: env(safe-area-inset-bottom); */
  /* transform: translate(-50%, -50%); */
  width: 100%;
  height: 100%;
  margin: auto;
}


.artistCardTopLabelContainer {
  position: absolute;
  color: white;
  font-weight: bold;
  top: 5%;
  left: 3%;
  display: flex;
}

.artistCardTopLabelImage {
  width: 34pt;
  height: 56pt;
}

.artistCardTopLabelTextcontainer {
  display: block;
  position: absolute;
  top: 50%;
  left: 110%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}



.imageStackContainer {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
}

.imageStackBackground {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.imageStackCutout {
  max-height: 100%;
  max-width: 100%;
  border-radius: 30px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  right: 0;
  height: 100%;
}
