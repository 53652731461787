@media screen and (min-width: 600px) {
  .comingSoonContainer {
    width: 50%;
    margin: 0 auto;
  }

  .verticalSlider {
    max-width: 600px;
    left: 50%;
  }
  .video-placeholder-container {
    max-width: 600px;
    position: absolute;
    left: 50%;
    width: 43vw;
    transform: translate(-50%, 0%);
  }

  .containerFullScreenDial {
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .contestantListContainerRelative {
    width: 600px;
  }
}
