.loginModalContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loginModalCard {
  position: relative; 
  width: 400px;
  background-color: rgba(32,1,35,1);
  border: 1px solid #ffffff;
  border-radius: 15px;
  box-shadow: 0px 0px 24px rgba(87, 87, 87, 0.5); 
  padding: 25px;
  opacity: 0.9;
  display: flex;
  flex-direction: column; 
  align-items: center; 
}

.cancelIcon {
  position: absolute; 
  top: 20px; 
  right: 20px; 
  color: white;
  cursor: pointer;
}

.contentContainer {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}