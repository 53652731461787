.artistListWrapper {
  display: grid;
  grid-auto-rows: 100px;
  grid-template-columns: 1fr 1fr 1fr;
  /* new */
  grid-gap: 10px;
  margin-left: 16pt;
  margin-right: 16pt;
 
}

.artistGridContainer {
  padding-top: 52pt;
  padding-bottom: 50pt;
  overflow: auto;
  height: auto;
  display: block;
  position:absolute;
  bottom:0;
  top:0;
  left:0;
  right:0;
  max-width: 500px;
  margin: auto;
}