.voteButtonContainer {
  display: flex;
  background-color: #ef243c;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.eventVoteButtonContainer {
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.voteButton {
  background-image: url(../../assets/heart.png);
  height: 27px;
  width: 27px;
  background-size: cover;
  margin: auto;
}


.eventVoteButton {
  font-family: 'Gotham';
  height: 27px;
  width: 40px;
  margin: auto;
  background-size: 17px 27px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

.outOfVotesButton {
  font-family: 'Gotham';
  font-size: 9px;
  height: 25px;
  width: 34px;
  margin: auto;
  background-size: 17px 27px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  white-space: nowrap;
}

.outOfVotesButton span {
  font-size: 14px;
  font-weight: bold;
}

.voteButtonRoot {
  height: 60px;
  width: 60px;
  /* background: radial-gradient(circle at center, transparent 60%, white 25.5%); */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voteRing {
  height: 45px;
  width: 45px;
  position: absolute;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  border-radius: 50%;
  top: 131.5px;
  right: 16px;
  bottom: 0;
  border: 2px white solid;
}



.sessionArtistVotesTotal {
  font-weight: bold;
  margin-top: 2px;
  height: 60px;
  width: 60px;
}

.voteAnimation {
  position: absolute;
  font-size: 20px;
  opacity: 0;
  transition: opacity 3s ease-in-out;
}

.voteAnimationIn {
  opacity: 1;
}
