.parent-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Adjust as needed */
}

.scrollable-div {
  overflow: auto;
  flex-grow: 1; /* Takes up the available space */
  padding-bottom: 100px;
  padding-top: 36px;
}