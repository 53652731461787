.dialog {
  /* background-color: white; */
  /* position: "absolute" !important;
  left: 0px !important;
  bottom: 0px !important; */
  background-color: white !important;
  color: black !important;
  /* width: 100% !important; */
  top: 70vh;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.dialogAndroid {
  /* background-color: white; */
  /* position: "absolute" !important;
  left: 0px !important;
  bottom: 0px !important; */
  background-color: white !important;
  color: black !important;
  /* width: 100% !important; */
  top: 70vh;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

@supports (-webkit-touch-callout: none) {
  .dialog {
    /* background-color: white; */
    /* position: "absolute" !important;
    left: 0px !important;
    bottom: 0px !important; */
    background-color: white !important;
    color: black !important;
    /* width: 100% !important; */
    top: 68vh !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }
}

.textButton {
  font-weight: bold !important;
  /* width: 75% */
}

.dialogContentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  /* flex: 1 1 0px; */
  /* justify-content: center; */
}

.closeDialog {
  position: absolute;
  top: 0px;
  right: 0px;
}

.openNativeAppTextContainer {
  display: flex;
  align-items: center;
}

.highlightText {
  font-weight: bold !important;
}

.logoIcon {
  height: 75%;
  width: 75%;
  border-radius: 20px;
  object-fit: contain;
}

.dialogButton {
  margin-top: 20px;
  cursor: pointer;
}

.button {
  width: 88vw;
}

.modalContainer {
  margin-bottom: 200px;
}
