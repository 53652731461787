.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  text-align: center;

}

.modal-content {
  margin: 20px;
  background-color: gray;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  position: absolute;
  color: white;

}

.modal-content h6{
  margin-top: 0px; 

}
.modal-content > h2 {
  margin-bottom: 0; /* Elimina el margen inferior solo del primer h2 */
}

.voteButtonContainer {
  display: flex;
  background-color: #ef243c;
  border-style: solid;
  border-color: white;
  padding: 5px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  justify-content: center;
  color: white;
  align-items: center;
  align-self: center;
  margin: auto;
}

.modal-content button {
  margin-top: 10px;
  height: 50px;
  width: 200px;
  border-radius: 5px;
  font-size: 20px;
  background-color: #ef243c;
  color:white;
  border-style:solid;
  border-color: white;
}
