.container {
    border-style: dashed;
    border-color: rgbA(151, 151, 151, 0.4);;
    border-width: 1px;
    height: 70px;
    margin: 10px;
    position: relative;
}

.buttonTrigger {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-style: hidden;
}

.imageUploadInput {
    display: none;
}


.input {
    width: 100%;
}

.btn {
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.0);
    color: rgbA(151, 151, 151, 0.4);;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.progress {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    color: red;
    display: grid;
    justify-items: center;
}

.loading {
    margin: auto;
    color: red;
    width: 15px;
    height: 15px;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.uploadIcon {
    width: 35px;
    height: 35px;
    color: rgbA(151, 151, 151, 0.4);
}

.completeIcon {
    display: block;
    color: green;
    margin-left: auto;
    margin-right: auto;
}

.formControl {
    min-width: 150px,
}

.innerButtonContainer {
    display: grid;
    justify-items: center;
}