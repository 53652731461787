.product {
  border: 1px solid #ddd;
  color: white;
  border-radius: 4px;
  padding: 16px;
  margin: auto;
  width: 300px;
  text-align: center;
  margin-bottom: 20px;
}


.product h2 {
  margin: 8px 0;
  color: white;
}

.product button {
  background-color: #c64b55;
  border: none;
  color: black;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.product p {
  text-align: start;
  font-size: 0.8rem;
}