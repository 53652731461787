@font-face {
  font-family: Poppins;
  src: url('../../fonts/Poppins-Bold.ttf');
}
@font-face {
  font-family: Death Star;
  src: url('../../fonts/Death\ Star.otf');
}
@font-face {
  font-family: Inter;
  src: url('../../fonts/Inter-VariableFont_slnt\,wght.ttf');
}
.confirmation-container {
  color: #ffffff;
  /* Color de letra blanco */
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #333333;
  /* Fondo oscuro */
  border-radius: 5px;
  text-align: center;
  /* Alineación horizontal centrada */
  margin-top: 10px;
}

.confirmation-container p {
  margin-bottom: 10px;
}

.confirmation-container ul {
  padding: 0;
}

.confirmation-container li {
  align-self: left;
  list-style-type: none;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.confirmation-container button {
  margin-top: 15px;
  /* Espacio entre el último elemento y el botón */
  margin: 0 auto;
  /* Centrado horizontal */
  display: block;
  /* Hace que el botón sea de bloque para aplicar margen automático */
}

.subscription-Final-Step {
    color: #ffffff;
    /* Color de letra blanco */
    font-family: Arial, sans-serif;
    padding: 20px;
    /* Fondo oscuro */
    border-radius: 5px;
    text-align: center;
    /* Alineación horizontal centrada */
    margin-top: 0px;
    padding-top: 20px;
    width: auto;
    height: 640px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../assets/signupbg.png');
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}

.final-step-box {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 370px;
}

.red-text {
  font-family: Poppins;
  color: #D81F37
}

.death-star-title{
  font-family: Death Star;
  letter-spacing: 1px;
}

.confirmationButton {
  background-color: #D81F37!important;
  color: #ffffff;
  width: 370px;
  border-radius: 10px;
  padding: 14px!important;
  font-family: Inter!important;
  }

.details-line {
  height: 0px;
  width: 370px;
  border-color: rgba(151, 151, 151, 0.37);
  border-style: solid;
  border-width: 0.01px;

}

.details {
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 768px) {
  .confirmation-container {
    padding: 10px;
  }
}