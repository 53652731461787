
.formContainer {
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
    color: white;
  }

@media screen and (min-width: 600px) {
  .formContainer {
    width: 400px;
    
  }
}

.attachText {
    display: block;
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
    color: white;
    font-weight: bold;
}
.exampleButton {
    color: red;
    background-color: transparent;
    border: none;
    padding: 0px;
    font-weight: bold;
    font-size: 16px;
}

.maxText {
    padding-left: 10px;
    font-weight: normal;
    font-size: 11px;
}
