.signInContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.itemsContainer {
  width: 300px;
  padding: 25px;
  height: fit-content;
}

.signInTypography {
  color: white;
  margin-top: 20px;
  font-size: 14px;
}

.howSignInTypography {
  color: white;
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  max-width: 300px;
}

.signInButton {
  font-family: 'Gotham Bold' !important;
  color: white;
  background-color: black;
  font-size: 17px;
  padding: 0px;
  border-width: 0px;
  margin-top: 16px;
  width: 100%;
  max-width: 400px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
}

.signInButton > * {
  margin-left: 20px;
}

.singUpButtonImage {
  width: 20px;
  height: 30px;
  object-fit: contain;
  cursor: pointer;
}

.signUpButtonsContainer {
  display: inline-block;
}