.container {
    width: 80%;
    height: 300px;
    margin: auto;
    background-color: black;
    border:none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: white;
    justify-items: center;
    display: grid;
    text-align: center;
}

.container:focus { outline: none; }

.gotItButton {
    background-color: #f90103;
    border: none;
    border-radius: 4px;
    width: 80%;
    color: white;
    font-weight: bold;
    height: 35px;
}

.exampleTitle {
    margin: 10px;
}

.exampleImage {
    width: 80%;
    background-color: gray;
    height: 200px;
    object-fit: contain;
}