.video {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
  /* scroll-snap-align: start; */
}

.videoSideBar {
  color: white;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .videoSideBar {
    position: absolute;
    z-index: 9999;
  }
}

.videoSideBarButton {
  padding: 10px;
  text-align: center;
}

.videoSideBarButton > img {
  display: inline-block;
}

.volumeIcon {
  background-color: rgb(0, 0, 0, .5);
  border: 1px solid white;
  border-radius: 100%;
  padding: 3.5px;
  cursor: pointer;
}

.shareIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.voteButtonContainer {
  display: flex;
  background-color: #d34962;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.voteButton {
  background-image: url(../../../assets/heart.png);
  height: 25px;
  width: 25px;
  background-size: cover;
  margin: auto;
}

.bigPlayButton {
  display: none !important;
}

.placeHolder {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: relative;
}

.containerVotesSidebar {
  height: 300px;
  position: absolute;
  bottom: 10vh;
  z-index: 1;
  right: 0;
  pointer-events: auto;
}

.safariVideo {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}
