.eventContainer {
  height: 100%;
  /* overflow-y: scroll; */
  /* -webkit-overflow-scrolling: touch;  */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.eventContainer::-webkit-scrollbar {
  display: none;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  object-fit: contain;
  height: 20px;
  width: 30%;
  padding: 10px 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  z-index: 10;
}

.centerGrid {
  height: 100%;
  display: grid;
  place-items: center;
}

.cityTab {
  text-transform: none;
  color: rgba(169, 169, 169, 0.5);
  font-weight: bold;
  /* border-top: 2px solid #281b2b; */
  border-bottom: 1px solid rgba(169, 169, 169, 0.1);
  width: 100%;
  min-width: 100%;
  text-align: center;
  padding: 4px 0px;
}

.tabInkBar {
  z-index: 10;
  position: relative;
  max-width: 35px;
  width: 100%;
  height: 3px;
  background-color: red;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.dialImage {
  width: 100%;
  height: 100%;
  padding: 10px 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}

.citySessionSliderContainer {
  position: absolute;
  top: 0;
  bottom: 0;
}

.videoContainer {
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100vw;
}
.videoContainer  > *:nth-child(1) > *:nth-child(1) {
  position: fixed;
}

.topGradient {
  height: 15vh;
  background: linear-gradient(0deg, transparent, black 80%) no-repeat;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

.loading {
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
}

.cardBottom {
  position: absolute;
  height: 20%;
  background-color: black;
  opacity: 0.75;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  color: white;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 0px !important;
}

.artistName {
  bottom: 0%;
  left: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 25px;
  padding: 5px;
}

.containerFullScreen {
  width: 85%;
  height: 130%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  overflow: auto;
  max-width: 400px;
}

.imageStackContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.imageStackBackground {
  max-height: 100%;
  max-width: 100%;
  border-radius: 30px;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.imageStackCutout {
  max-height: 100%;
  max-width: 100%;
  border-radius: 30px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  right: 0;
  height: 100%;
}

.artistCardContainer {
  width: 85%;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  /* bottom: 0; */
  margin: auto;
  max-width: 400px;
}

.artistCard {
  position: absolute;
  left: 50%;
  top: 50%;
  padding-bottom: env(safe-area-inset-bottom);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 65%;
  margin: auto;
  max-width: 400px;
}

.cardBorder {
  padding: 3pt;
  border-radius: 30px;
  background: linear-gradient(
    0deg,
    rgba(235, 182, 64, 1) 0%,
    rgba(125, 89, 13, 1) 100%
  );
  position: absolute;
  left: 50%;
  top: 50%;
  padding-bottom: env(safe-area-inset-bottom);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 65%;
  margin: auto;
  max-width: 400px;
}

.artistCardTopLabelContainer {
  position: absolute;
  color: white;
  font-weight: bold;
  top: 5%;
  left: 3%;
  display: flex;
}

.artistCardTopLabelImage {
  width: 34pt;
  height: 56pt;
}

.artistCardTopLabelTextcontainer {
  display: block;
  position: absolute;
  top: 50%;
  left: 110%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.detailContainer {
  color: black;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  color: white;
}
.detailContainer > div {
  padding: 5px;
}

.detailContainerItem {
  display: flex;
}

.detailContainerItemImg {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.detailContainerItemValue {
  margin-left: 5px;
}

.sessionStatusLabel {
  color: white;
  font-size: 12pt;
  font-weight: bold;
  background-color: #d81f37;
  border-radius: 7px 0px 0px 7px;
  height: 23pt;
  width: 70pt;
  position: absolute;
  right: 0px;
  top: 100px;
  display: flex;
  z-index: 10;
}

.swiperButtonNext {
  background-image: url("../../assets/next-arrow.png");
  width: 40px;
  height: 38px;
  position: absolute;
  top: 40%;
  right: 20%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
}

.swiperButtonPrev {
  background-image: url("../../assets/prev-arrow.png");
  width: 40px;
  height: 38px;
  position: absolute;
  top: 40%;
  left: 20%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
}



.swipeIndicator {
  background-color: rgba(169, 169, 169, 0.3);
  
  width: 100%;
  bottom: 0px;
  border-radius: 30px 30px 0px 0px;
  padding-bottom: env(safe-area-inset-bottom);
  height: 100px !important;
  position: absolute;
  
}


@supports (-webkit-touch-callout: none) {
  .artistCard {
    top: 43%;
    height: 55%;
  }
  .cardBorder {
    top: 43%;
    height: 55%;
  }
  .swipeIndicator { 
    bottom: 12vh;
  }
}

.swipeIndicatorContent {
  position: absolute;
  width: 250px;
  height: 30px;
  top: 10px;
  color: white;
  font-size: 11;
  font-weight: bold;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.swipeIndicatorContent > * {
  margin: 3pt
}

.swipeIndicator :hover {
  cursor:pointer;
 }

.swipeIndicatorContent > *:hover {
  cursor:pointer;
 }


.pendingToStartGrid {
  padding-top: 70pt;
  padding-bottom: 50pt;
  overflow: auto;
  height: auto;
  display: block;
  position:absolute;
  bottom:0;
  top:0;
  left:0;
  right:0;
  max-width: 500px;
  margin: auto;
}

