/* ButtonStyles.css */
.stripe-pay-button {
    background-color: #556cd6;  /* Stripe-like blue */
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
    margin: 10px calc(90% - 50vw);
}

.stripe-pay-button:hover {
    background-color: #4253af;  /* Slightly darker blue on hover */
}

.stripe-pay-button:disabled {
    background-color: #a5a5a5;  /* Grayed out when disabled */
    cursor: default;
}