.fanProfileContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.itemsContainer {
  margin-top: 10%;
  width: 300px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.fanInfoContainer {
  padding: 10px;
}

.fanInfoContainer > * {
  margin: 10px;
}

.signInButton {
  color: white;
  background-color: black;
  font-size: 17px;
  padding: 0px;
  border-width: 0px;
  width: 100%;
  max-width: 400px;
  height: 48px;
  display: inline-flex;
  align-items: center;
}

.fanProfileImage {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.fanName {
  color: white;
  font-weight: bold;
  font-size: 32px;
}

.fanLabel {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #979797;
  border-color: #979797;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  width: 54px;
  height: 21px;
}

.cityLabel {
  color: #979797;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cityIcon {
  width: 15px !important;
  height: 15px !important;
}

.cityNameLabel {
  text-align: center;
  margin-left: 2px;
  font-size: 14px;
  font-weight: 600;
}

.statisticsContainer {
  flex-direction: row;
  display: flex;
  height: 80px;
  border-top: 1px solid rgbA(151, 151, 151, 0.4);
  width: 100%;
  border-bottom: 1px solid rgbA(151, 151, 151, 0.4);
  color: white;
  align-items: center;
  justify-content: center;
}

.statisticLabel {
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.statisticLabel > * {
  margin: 6px;
  font-weight: bold;
}

.statisticLabelBottom {
  font-size: 12px;
}

.bottomButtons {
  display: flex;
  flex-direction: row;
  margin: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;
}

.bottomButtons > * {
  margin: 0 auto;
  border-width: 0px;
  padding: 0px;
  border-radius: 8pt;
}

.sendArtistRequestButton {
  height: 45px;
  max-width: 318px;
  margin-right: 8px;
  background-color: #f90103;
  color: white;
  font-size: 15px;
  padding: 5px;
  font-weight: bold;
  width: 290px;
}

.editProfileButton {
  width: 45px;
  height: 45px;
  background-color: transparent;
  color: #979797;
  border-width: 1px;
  border-color: #979797;
  border-style: solid;
}

.elipsisIcon {
  color: white;
  height: 60px !important;
  width: 60px !important;
  margin: 5px;
  position: absolute;
}


.sendArtistRequestButtonDisabled {
  height: 45px;
  max-width: 318px;
  margin-right: 8px;
  background-color:gray;
  color: white;
  font-size: 15px;
  padding: 5px;
  font-weight: bold;
  width: 290px;
}