/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  /* -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0); */
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* CUSTOM */

.dial-slider {
  position: absolute;
  z-index: 10;
  width: 20vw;
  top: 40%;
  margin-left: 5px;
  pointer-events: none;
}

/* .contestant-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.contestant-slider > div:first-of-type {
  height: 100%;
} */

.body-slider {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
}

.body-slider > div:first-of-type {
  height: 100%;
}

.city-tab-slider {
  z-index: 10;
}

.city-tab-slider .slick-center div > div {
  color: rgba(255, 0, 0, 1);
}

.city-tab-slider .slick-center div > span {
  max-width: 40px;
  width: 100%;
  height: 2px;
  background-color: red;
}

.body-slider .slick-track {
  height: 100%;
}

.body-slider .slick-slide > div:first-of-type {
  height: 100%;
}

.cardSlider {
  position: relative;
  width: 100vw;
  top: 0;
  bottom: 0;
  margin: auto;
}
.cardSlider .slick-slide > div:first-of-type {
  width: 90%;
}

/* .cardSlider .slick-slide.slick-current > div:first-of-type {
  width: 90% !important;
} */

.cardSlider .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-current > div > div {
  display: block !important;
}
/* .cardSlider .slick-list{
  height: 80vh;
} */

